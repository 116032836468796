$font-family: Lexend;
$font-size-base: 14px;

$color-clinical-dark: #1C2633;
$color-clinical-grayscale-solid: #475467;
$color-clinical-grayscale-solid-400: #E4E7EC;
$color-clinical-grayscale-solid-600: #98A2B3;
$color-citeline-navy: #14123B;
$color-citeline-button: #0F847C;
$color-citeline-disabled-button: #E4E7EC;
$color-background: #F5F5F5;
$color-citeline-link: #0F847C;
$color-citeline-focus: rgba(15,132,124, 0.15);
$color-citeline-clinical-semantic-error-main: #CC322C;
$color-citeline-clinical-semantic-error-lighter:#FDCCD0;


$color-cd-button: #257EA4;
$color-cd-link: #257EA4;


@mixin font($family, $weight, $size, $lineHeight) {
  font-family: $family;
  font-weight: $weight;
  font-size: $size;
  line-height: $lineHeight;
}

@mixin FontBodySmallRegular {
  @include font($font-family, 400, 12px, 16px);
}

@mixin FontBodySmallLight {
  @include font($font-family, 300, 12px, 16px);
}

@mixin FontMediumRegular {
  @include font($font-family, 400, 14px, 1.43);
}

@mixin FontLargeRegular {
  @include font($font-family, 400, 16px, 1.25);
}

@mixin FontXLargeBold {
  @include font($font-family, 700, 18px, 2);
}

@mixin FontXXLargeBold {
  @include font($font-family, 600, 24px, 1.5);
}


@mixin FontButton {
  @include FontMediumRegular;
}

@mixin Button {
  :global(.MuiButtonBase-root.MuiButton-root) {
    @include FontButton;
    height: 40px;
    padding: 11px 16px;
    text-transform: capitalize;
    background: $color-citeline-button;

    &:hover {
      background: $color-citeline-button;
      box-shadow: inset 0 0 0 150px rgba(0, 0, 0, 0.1);
    }

    &:global(.Mui-disabled) {
      color: $color-clinical-grayscale-solid-600;
      background: $color-citeline-disabled-button !important;

      &:hover {
        background: $color-citeline-disabled-button !important;
      }
    }
  }
}

html {
  font-size: $font-size-base;
}

body {
  @include Button
}

a {
  color: $color-citeline-link;
  text-decoration: none;
  padding: .2rem;
  border-radius: .2rem;
  &:focus {
    background: $color-citeline-focus;
  }
}

.buttons-container {
  margin-top: 15px;
}

.align-center {
  display: flex;
  justify-content: center;
}

